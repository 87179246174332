<template>
    <audio id="track-playback" ref="audio" @ended="endPlay" controls @timeupdate="needQuestion">
        <source :src="nextTrack?.file" type="audio/mpeg" />
    </audio>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { defineComponent } from 'vue'
import { useTrackStore } from '@/store'

export default defineComponent({
    methods: {
        startPlay() {
            this.$refs.audio.play()
        },
        pausePlay() {
            this.$refs.audio.pause()
        },
        stopPlay() {
            this.$refs.audio.load()
        },
        endPlay() {
            this.started = false
            this.currentQuestion = this.currentQuestion || this.getQuestion(this.nextTrack?.question_id)
        },
        needQuestion(event) {
            if (this.nextTrack.start_record && !this.currentQuestion && this.nextTrack.start_record < event.target.currentTime) {
                this.currentQuestion = this.getQuestion(this.nextTrack?.question_id)
            }
        },
        ...mapActions(useTrackStore, ['getQuestion'])
    },
    watch: {
        started(newValue) {
            newValue ? this.startPlay() : this.pausePlay()
        },
        nextTrack() {
            this.stopPlay()
        }
    },
    computed: {
        ...mapState(useTrackStore, ['nextTrack']),
        ...mapWritableState(useTrackStore, ['started', 'currentQuestion'])
    }
})
</script>

<style lang="scss">
audio {
    display: none;
}
</style>
