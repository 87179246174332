<template>
    <router-view />
</template>

<script>
import { useTrackStore } from './store'
import 'bootstrap/dist/js/bootstrap'

export default {
    setup() {
        const store = useTrackStore()
        store.fillStore()
    }
}
</script>

<style>
@import '~bootstrap/dist/css/bootstrap';
</style>
