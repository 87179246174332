<template>
    <div class="button-container" id="start-button-container" :class="{ hide: currentQuestion }">
        <button @click="sendEvent()" :class="{ start: started }">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" id="playpause"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Play</title>
                <polygon points="12,0 25,11.5 25,39 12,50" id="leftbar" />
                <polygon points="25,11.5 39.7,24.5 41.5,26 39.7,27.4 25,39" id="rightbar" />
                <animate to="7,3 19,3 19,47 7,47" id="lefttopause" xlink:href="#leftbar" attributeName="points"
                    dur=".3s" begin="indefinite" fill="freeze" />
                <animate to="12,0 25,11.5 25,39 12,50" id="lefttoplay" xlink:href="#leftbar" attributeName="points"
                    dur=".3s" begin="indefinite" fill="freeze" />
                <animate to="31,3 43,3 43,26 43,47 31,47" id="righttopause" xlink:href="#rightbar"
                    attributeName="points" dur=".3s" begin="indefinite" fill="freeze" />
                <animate to="25,11.5 39.7,24.5 41.5,26 39.7,27.4 25,39" id="righttoplay" xlink:href="#rightbar"
                    attributeName="points" dur=".3s" begin="indefinite" fill="freeze" />
            </svg>
        </button>
    </div>
</template>

<script>
import { useTrackStore } from '@/store'
import { mapWritableState } from 'pinia'

export default {
    methods: {
        startAnimate() {
            if (!this.started) {
                const lefttoplay = document.querySelector('#lefttoplay')
                lefttoplay?.beginElement()
                const righttoplay = document.querySelector('#righttoplay')
                righttoplay?.beginElement()
                return
            }
            const lefttopause = document.querySelector('#lefttopause')
            lefttopause?.beginElement()
            const righttopause = document.querySelector('#righttopause')
            righttopause?.beginElement()
        },
        sendEvent() {
            this.started = !this.started
        }
    },
    emits: [
        'togglePlay'
    ],
    watch: {
        started() {
            this.startAnimate()
        }
    },
    computed: {
        ...mapWritableState(useTrackStore, ['started', 'currentQuestion'])
    }
}
</script>

<style lang="scss" scoped>
.button-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #333333;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        width: 100%;
        height: 100%;
        background: transparent;
        outline: none;
        border: none;
        cursor: pointer;
        transition: all 0.5s ease;
        opacity: 1;

        // &.start {
        //     opacity: 0;
        // }

        svg {
            width: 100%;
            margin: 0 auto;
            fill: #fff;
            padding: 3rem;
            transition: .6s opacity;
        }

    }

    &.hide {
        display: none;
    }
}
</style>
