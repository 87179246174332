<template>
    <div class="speech-container">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1"
            x="0px" y="0px" viewBox="0 0 96.667 96.666" style="enable-background:new 0 0 96.667 96.666"
            xml:space="preserve">
            <g>
                <g>
                    <path
                        d="M48.333,73.296c9.519,0,17.263-7.744,17.263-17.262V17.262C65.596,7.743,57.852,0,48.333,0    c-9.519,0-17.262,7.743-17.262,17.262v38.773C31.071,65.553,38.814,73.296,48.333,73.296z"
                        fill="#989898" />
                    <path
                        d="M76.078,45.715h-3.437c-1.104,0-2,0.896-2,2v7.029c0,12.3-10.008,22.308-22.309,22.308S26.025,67.044,26.025,54.744    v-7.029c0-1.104-0.896-2-2-2h-3.437c-1.104,0-2,0.896-2,2v7.029c0,14.707,11.433,27.667,26.026,29.506v4.98h-15.35    c-1.104,0-2,0.896-2,2v3.436c0,1.104,0.896,2,2,2h38.138c1.104,0,2-0.896,2-2V91.23c0-1.104-0.896-2-2-2H52.051v-4.98    c14.594-1.838,26.026-14.799,26.026-29.506v-7.029C78.078,46.61,77.182,45.715,76.078,45.715z"
                        fill="#989898" />
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useTrackStore } from '@/store'

export default {
    data() {
        return {
            recognition: null
        }
    },
    mounted() {
        const choise = this.currentQuestion?.answers?.map(item => item.answer_text) || []
        const grammar = '#JSGF V1.0 grammar answer public <answer> = ' + choise.join(' | ') + ' '
        this.recognition = new (window.webkitSpeechRecognition || window.SpeechRecognition)()
        const speechRecognitionList = new (window.webkitSpeechGrammarList || window.SpeechGrammarList)()
        speechRecognitionList.addFromString(grammar, 1)
        this.recognition.grammars = speechRecognitionList
        this.recognition.lang = 'ru-Ru'
        this.recognition.interimResults = false
        this.recognition.maxAlternatives = 3
        this.recognition.continuous = true
        this.recognition.onresult = event => {
            const result = event.results[event.resultIndex]
            if (result.isFinal) {
                const res = result[0].transcript.toLowerCase().replace(/[^а-я]/, '').split(' ')
                const findedIndex = choise.findIndex(r => res.includes(r))
                if (findedIndex > -1) {
                    this.switchTrack(findedIndex)
                }
            }
        }
        this.recognition.onend = () => {
            this.currentQuestion ? this.startRecognition() : this.stopRecognition()
        }
        this.startRecognition()
    },
    methods: {
        startRecognition() {
            this.recognition?.start()
        },
        stopRecognition() {
            if (this.recognition) {
                this.recognition.stop()
                this.recognition = null
            }
        },
        ...mapActions(useTrackStore, ['switchTrack'])
    },
    computed: {
        ...mapState(useTrackStore, ['currentQuestion'])
    },
    unmounted() {
        this.stopRecognition()
    }
}
</script>

<style lang="scss">
.speech-container {
    height: 300px;

    svg {
        max-height: 100%;
        max-width: 100%;
    }

    @media screen and (max-width: 768px) {
        height: auto;
    }
}
</style>
