<template>
    <div v-if="currentQuestion" class="text-center">
        <h2>{{ currentQuestion?.question_text }}</h2>
        <SpeechRecognitionComponent />
        <div class="justify-content-center my-3">
            <button class="btn btn-primary mx-3" v-for="(item, i) in currentQuestion.answers" :key="item.id"
                @click="setChoise(i)">{{ item.answer_text }}</button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useTrackStore } from '@/store'
import SpeechRecognitionComponent from '@/components/SpeechRecognitionComponent.vue'

export default {
    methods: {
        setChoise(index) {
            this.switchTrack(index)
        },
        ...mapActions(useTrackStore, ['switchTrack'])
    },
    components: {
        SpeechRecognitionComponent
    },
    computed: {
        ...mapState(useTrackStore, ['currentQuestion'])
    }
}
</script>
