import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/pages/HomePage.vue'
// import { pinia, useTrackStore } from '@/store'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage,
        beforeEnter: () => {
            // eslint-disable-next-line no-unused-vars
            // const processStore = useTrackStore(pinia)
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
