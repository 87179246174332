import { defineStore, createPinia } from 'pinia'
import taleList from '@/assets/data/tale.json'
import trackList from '@/assets/data/track.json'
import questionList from '@/assets/data/question.json'
import answerList from '@/assets/data/answer.json'
import { computed, ref } from 'vue'

export const pinia = createPinia()

export const useTrackStore = defineStore('track', () => {
    const tale = ref([])
    const track = ref([])
    const question = ref([])
    const answer = ref([])

    const taleName = ref('')
    const nextTrack = ref(null)
    const currentQuestion = ref(null)
    const started = ref(false)

    function fillStore() {
        tale.value = taleList
        track.value = trackList
        question.value = questionList
        answer.value = answerList

        nextTrack.value = getTrack.value(tale.value[0].track_id)
        taleName.value = tale.value[0].name
    }

    function switchTrack(findedIndex) {
        console.log('switch track')
        started.value = false
        nextTrack.value = getTrack.value(currentQuestion.value.answers?.[findedIndex].track_id) || null
        currentQuestion.value = null
        setTimeout(() => {
            started.value = true
        }, 300)
    }

    const getTrack = computed(() => {
        return (trackId) => {
            const track = trackList.find(track => track.id === trackId)
            if (!track) {
                return undefined
            }
            return {
                id: track.id,
                file: track.file,
                question: getQuestion.value(track.question_id),
                question_id: track.question_id,
                start_record: track.start_record
            }
        }
    })

    const getQuestion = computed(() => {
        return (questionId) => {
            const question = questionList.find(question => question.id === questionId)
            if (!question) {
                return undefined
            }
            return {
                id: question.id,
                question_text: question.qeustion_text,
                answers: getAnswers.value(question.id)
            }
        }
    })

    const getAnswers = computed(() => {
        return (questionId) => {
            const question = questionList.find(question => question.id === questionId)
            if (!question) {
                return []
            }
            const answers = []
            answerList.every(answer => {
                if (answer.question_id === question.id) {
                    answers.push({
                        id: answer.id,
                        answer_text: answer.answer_text,
                        track: getTrack.value(answer.track_id),
                        track_id: answer.track_id
                    })
                }
                return true
            })
            return answers
        }
    })

    return { nextTrack, currentQuestion, started, taleName, fillStore, switchTrack, getTrack, getQuestion, getAnswers }
})
