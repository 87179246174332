<template lang="html">
    <div class="container">
        <div class="tale-name h1 text-center">
            {{ taleName }}
        </div>
        <QuestionComponent />
        <div class="tale-play">
            <StartButtonComponent />
            <PlayElementComponent />
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import { useTrackStore } from '@/store'
import StartButtonComponent from '@/components/StartButtonComponent.vue'
import PlayElementComponent from '@/components/PlayElementComponent.vue'
import QuestionComponent from '@/components/QuestionComponent.vue'

export default {
    components: {
        StartButtonComponent,
        PlayElementComponent,
        QuestionComponent
    },
    computed: {
        ...mapState(useTrackStore, ['taleName', 'currentQuestion'])
    }
}
</script>

<style lang="scss">
.tale-play {
    position: relative;
    height: 300px;
    border-radius: 50%;
    overflow: hidden;
    aspect-ratio: 1;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
        height: auto;
    }
}
</style>
